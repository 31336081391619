var app = (function ($) {
    var pub = {
        init: function () {
            //initCountProduct();
            //initQuestion();
            initTop();
            initMasks();
            menuToggle();
            
            $('#closed').on('click', function (e) {
                $(this).parents('#products-menu').slideUp(300);
            });
            $('.toggle-products').on('click', function (e) {
                e.preventDefault();
                $('#products-menu').slideDown(300);
            })
        },
        initSliderProductCategory: function () {
            $('.slider-wrapper').slick({
                //autoplay: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                infinite: false,
                speed: 900
            });
        },

        initSliderNews: function () {
            $('.articles-box').slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 5000,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        },

        initSliderOfficials: function () {
            $(".officials").slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 479,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        },

        initScroll: function () {
            $("[class^=uf_options_]:not(#uf_prices)").mCustomScrollbar();
        },

        initSliderMail: function () {
            $(".slider-mail .slider").slick({
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }

        /*initYmap: function () {
            ymaps.ready(function () {
                var map = new ymaps.Map('ya-map', {
                        center: [45.003353, 39.109685],
                        zoom: 16,
                        controls: ['zoomControl']
                    }),
                    placemark = new ymaps.Placemark([45.003353, 39.109685], {
                        hintContent: 'Россия, Краснодар, Горячеключевская улица, 2',
                        balloonContent: 'Россия, Краснодар, Горячеключевская улица, 2'
                    }, {
                        iconLayout: 'default#image',
                        iconImageHref: '/templates/tradeindustriya/images/map-point.png',
                        iconImageSize: [45, 60],
                        //iconImageOffset: [-3, -42]
                    });
                if ($('#map-mobile-detect:visible').length > 0) {
                    map.behaviors.disable('drag');
                }

                map.behaviors.disable('scrollZoom');
                map.geoObjects.add(placemark);
            });
        }*/
    };

    function menuToggle() {
        $('.menu-toggle').on('click', function (e) {
           e.preventDefault();
           $(this).parents('.menu-wrapper').find('.menu-list').slideToggle();
        });
    }

    function initMasks() {
        $(".hqPhone").mask("+7 (999) 999-99-99");
        $(".qoPhone").mask("+7 (999) 999-99-99");
        $(".cbPhone").mask("+7 (999) 999-99-99");
    }

    /*function initCountProduct() {
        var select = $('.box_products_count_to_page #limit');
        select.hide();
        select.each(function (idx, itm) {
            $(itm).find('option').each(function (index, item) {
                var $item = $(item);
                var link = $('<a href="#"></a>').html($item.html()).attr('data-key-option', $item.val());
                if ($item.attr('selected')) {
                    link.addClass('active');
                }
                link.on('click', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    $('#limit:first option').each(function (index2, item2) {
                        if ($(item2).val() == $this.data('key-option')) {
                            $(item2).attr('selected', 'selected').parent().trigger('change');
                        }
                    });
                });
                $(itm).before(link);
            })
        });
    }*/

    /*function initQuestion() {
        $('.formQuestion').on('submit', function (e) {
            e.preventDefault();
            var form = $(this);
            form.find('.rfield').removeClass('empty_field');
            var isError = false;
            form.find('.rfield:not(:checkbox)').each(function (index, item) {
                if ($(item).val() == '') {
                    $(item).addClass('empty_field');
                    isError = true;
                }
            });
            //то не понял
            var check = form.find('.licON');
            if (!check.prop("checked")) {
                isError = true;
                check.addClass('empty_field');
            }
            setTimeout(function () {
                form.find('.empty_field').removeClass('empty_field');
            }, 500);
            if (!isError) {
                $.ajax({
                    type: "POST",
                    url: 'http://' + document.domain + '/index.php?option=com_ajax&module=have_questions&format=raw',
                    data: form.serialize(),
                    cache: false,
                    dataType: 'json',
                    option: 'com_ajax',
                    module: 'havequestions',
                    success: function (data, textStatus, jqXHR) {
                        if (data.success == '1') {
                            form.html(data.text);
                        }
                    },
                    error: function () {
                        alert('Ошибка');
                    }
                });
            }
        });
    }*/

    function initTop() {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 0) {
                $('#toTop').fadeIn(300);
            } else {
                $('#toTop').fadeOut(200);
            }
        });

        $('#toTop').on('click', function (e) {
            TweenLite.to(window, 0.6, {scrollTo: 0});
            e.preventDefault();
        });
    }

    return pub;
})(jQuery);

jQuery(document).ready(function () {
    app.init();
});

jQuery(window).load(function () {
    app.initSliderOfficials();
    app.initSliderMail();
    app.initSliderProductCategory();
    app.initSliderNews();
    app.initScroll();
});

